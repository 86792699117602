<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <tabla-bodega-inventario
            :columnas="columns"
            :datos-tabla="items"
            :id-catalogo="idCatalogo"
            :nombre-tabla="nombreTabla"
            :nombre-catalogo="nombreCatalogo"
            :origen-reporte="origenPostes"
            :origen-reporte-tabla="origenPostesTabla"
            @actualizar="loadData"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import TablaBodegaInventario from '@/components/bodega/tablaBodegaInventario.vue'
import { BCard, BCol, BRow } from 'bootstrap-vue'
import { getlistado } from '@/utils/catalogos'

export default {
  components: {
    TablaBodegaInventario,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      nombreCatalogo: 'Tipos de Postes',
      nombreTabla: 'tipo_poste',
      idCatalogo: 1,
      items: [],
      origenPostes: {
        origen: 'DESPACHO',
        nombre: 'Postes',
        concordancia: 'los',
        concordanciaDos: 'establecidos',
      },
      origenPostesTabla: {
        origen: 'POSTES',
        nombre: 'Postes',
        concordancia: 'los',
        concordanciaDos: 'establecidos',
      },
      columns: [
        {
          label: 'Nombre',
          field: 'nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Ingresos',
          field: 'inventario.ingresos',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Despachos',
          field: 'inventario.despachos',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Existencia',
          field: 'existencia',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async beforeMount() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      try {
        this.items = await getlistado(this.idCatalogo, this.nombreCatalogo, true, this.usuario)
        if (this.items === null) this.items = []
      } catch (error) {
        console.error(`Error en cargar ${this.nombreCatalogo}`, error)
        this.items = []
      }
    },
  },
}
</script>
